.spinner {
  width: 50px;
  height: 50px;

  display: block;
  position: relative;
  z-index: 1;

  border-radius: 50%;
  border: 4px solid #e0e0e0;
  border-top-color: #0ebeff;

  transform: translate3d(-50%, -50%, 0);
  animation: spin 0.75s ease-in-out infinite;
}

// Bourbon mixins
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
