.mat-fab,
.mat-flat-button,
.mat-icon-button,
.mat-mini-fab,
.mat-raised-button,
.mat-stroked-button,
.mat-button {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  line-height: 1 !important;

  .mat-button-wrapper {
    // position: relative;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 2; /* Move mat-button-wrapper above the ripple and focus overlay */
  }

  /* Large button */
  &.red-mat-button-small {
    height: 32px;
    min-height: 32px;
    max-height: 32px;
  }

  &.red-mat-button-medium {
    height: 40px;
    min-height: 40px;
    max-height: 40px;
  }

  /* Large button */
  &.red-mat-button-large {
    height: 48px;
    min-height: 48px;
    max-height: 48px;
  }

  /* Lower the icon opacity on disabled buttons */
  &.mat-button-disabled {
    .mat-icon {
      opacity: 0.38 !important;
    }
  }
}

.mat-form-field {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .mat-form-field-label {
          @apply text-hint;
        }

        .mat-form-field-required-marker {
          color: var(--red-warn);
        }
      }

      .mat-form-field-prefix,
      .mat-form-field-suffix {
        .mat-icon,
        .mat-icon-button:not(.mat-button-disabled),
        .mat-select-value {
          @apply text-hint;
        }
      }
    }
  }
}

.mat-form-field.mat-form-field-appearance-fill {
  /* Default style tweaks and enhancements */
  &:not(.mat-form-field-disabled) {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        @apply bg-white  #{'!important'};
      }
    }
  }

  .mat-form-field-underline::before {
    @apply bg-theme-gray-border  #{'!important'};
  }

  &.bg-transparent {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        background-color: transparent !important;
      }
    }
  }

  &.phone-number-field {
    .mat-form-field-label-wrapper {
      left: -74px;
      top: 0;

      @media screen and (max-width: 640px) {
        left: -40px;
      }
    }

    .mat-form-field-flex {
      position: relative;
      display: flex;
      align-items: stretch;
    }

    .mat-form-field-should-float {
      .mat-form-field-prefix {
        visibility: hidden;
      }
    }

    .mat-select-arrow-wrapper {
      margin-top: 0.25rem;
    }

    .mat-form-field-prefix {
      // width: 84px;
      // top: 3px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      .mat-select {
        width: auto;
      }
    }

    .mat-form-field-infix {
      border: 0;
      padding: 0;
    }

    .phone-number-code {
      font-size: 0.875rem;
      line-height: 1rem;
    }

    .mat-input-element {
      margin-top: 0;
      padding: 15px 0;
    }
  }

  /* Removes subscript space */
  &.fuse-mat-no-subscript {
    .mat-form-field-wrapper {
      padding-bottom: 0;
      margin-bottom: 0;

      .mat-form-field-subscript-wrapper {
        display: none !important;
        height: 0 !important;
      }
    }
  }

  &.fuse-float-subscript {
    .mat-form-field-wrapper {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  &.has-border {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        border-width: 1px !important;
      }
    }
  }

  &.fuse-search-box {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        position: relative;
        display: flex;
        align-items: stretch;
        padding: 0 16px;
        border-width: 0px;

        .mat-form-field-prefix,
        .mat-form-field-suffix {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        .mat-form-field-infix {
          position: static;
          display: flex;
          align-items: center;
          width: 88px;
          padding: 0;
          border: 0;

          .mat-input-element {
            padding: 14px 0;
            margin-top: 0;
          }

          .mat-form-field-label-wrapper {
            top: -2px;
            left: 15px;
            height: auto;
            padding-top: 0;
            overflow: visible;
            pointer-events: auto;
          }

          .mat-select-placeholder {
            @apply text-theme-dark;
          }
        }
      }

      .mat-form-field-underline {
        display: none;
      }
    }
  }

  &.fuse-mat-float-label-always {
    .mat-form-field-wrapper {
      .mat-form-field-infix {
        .mat-form-field-label-wrapper {
          top: -25px;
          left: 0;
        }
      }
    }
  }

  /* Rounded */
  &.fuse-mat-rounded {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        border-radius: 24px;
      }
    }
  }

  /* Rounded */
  &.fuse-mat-flat {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        border-radius: 0;
        border-width: 0;
      }
    }
  }

  /* Rounded */
  &.fuse-mat-rounded-sm {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        border-radius: 2px;
      }
    }
  }

  /* Dense */
  &.fuse-mat-dense {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        min-height: 40px;

        .mat-form-field-prefix,
        .mat-form-field-suffix {
          .mat-icon-button {
            width: 32px;
            min-width: 32px;
            height: 32px;
            min-height: 32px;
          }
        }

        .mat-form-field-prefix {
          > .mat-icon-button {
            margin-left: -6px;
            margin-right: 12px;
          }
        }

        .mat-form-field-suffix {
          > .mat-icon-button {
            margin-left: 12px;
            margin-right: -6px;
          }
        }

        .mat-form-field-infix {
          .mat-input-element {
            padding: 11px 0;
          }
        }
      }
    }

    /* Rounded */
    &.fuse-mat-rounded {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          border-radius: 20px;
        }
      }
    }
  }

  &.cell-field {
    &.mat-form-field-disabled {
      .mat-form-field-flex {
        background-color: unset;
      }

      .mat-input-element {
        @apply text-current;
      }

      .mat-select-disabled {
        .mat-select-value {
          @apply text-current;
        }
      }

      .mat-select-arrow-wrapper {
        @apply invisible;
      }
    }
  }

  &.disabled-field {
    &.mat-form-field-disabled {
      .mat-form-field-flex {
        @apply bg-theme-line;
      }

      .mat-input-element {
        @apply text-current;
      }

      .mat-select-disabled {
        .mat-select-value {
          @apply text-current;
        }
      }

      .mat-select-arrow-wrapper {
        @apply invisible;
      }
    }
  }
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Select
/* ----------------------------------------------------------------------------------------------------- */
.mat-select {
  // display: inline-flex;

  // .mat-select-placeholder {
  //     transition: none !important;
  //     @apply text-hint #{'!important'};
  // }

  .mat-select-trigger {
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: auto;

    .mat-select-value {
      display: flex;
      position: relative;
      max-width: none;

      .mat-select-value-text {
        display: inline-flex;

        > * {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  mat-select-trigger {
    .mat-standard-chip {
      padding: 4px 8px;
      min-height: 24px;
    }
  }

  .mat-select-arrow-wrapper {
    transform: translateY(0) !important;

    .mat-select-arrow {
      margin: 0 4px 0 2px;
    }
  }
}

.mat-paginator {
  &.cimb-paginator {
    background: inherit !important;

    .mat-paginator-container {
      justify-content: space-between;

      .mat-paginator-page-size-select {
        .mat-form-field-outline {
          background-color: #ffffff;
          border-radius: 4px;
          top: 0.75em !important;
          border: 1px solid #cdd7df;
        }

        .mat-form-field-infix {
          padding: 0.5em 0 !important;
        }

        .mat-select-arrow-wrapper {
          transform: none;
        }
      }
    }
  }
}

.mat-snack-bar-container {
  &.toast-container {
    @apply bg-white #{'!important'};
  }
}

.mat-menu-trigger {
  &.select-btn {
    @apply bg-primary-100 #{'!important'};
  }
}

.mat-button {
  &.filter-btn {
    @apply bg-primary-100 #{'!important'};
  }
}

// Mat Tab Nav Bar
.mat-tab-nav-bar {
  @apply bg-theme-line border-none #{'!important'};
  .mat-tab-list {
    border-bottom: 1px solid #e2e8f0;

    .mat-tab-links {
      .mat-tab-link {
        @apply opacity-100;
        &.mat-tab-label-active {
          @apply text-primary bg-white;
        }
      }
    }

    .mat-ink-bar {
      bottom: unset;
      top: 0;

      &::after {
        content: '';
        position: absolute;
        bottom: -48px;
        border-top: 2px solid white;
        left: 0;
        right: 0;
        transition: none;
      }
    }
  }
}

// Mat Table
mat-table {
  mat-row,
  mat-header-row,
  mat-footer-row {
    // overflow: hidden;
    // border-bottom-color: transparent;
    // &.mat-table-sticky {
    //   @apply z-10 #{'!important'};
    // }
  }

  &.full-border {
    @apply border border-solid border-theme-gray-border box-border;
    .mat-header-row {
      @apply bg-theme-line #{'!important'};
    }

    .mat-header-cell:not(:first-of-type),
    .mat-cell:not(:first-of-type) {
      border-left: 1px solid #e0e0e0;
    }

    .mat-row:last-child {
      border-bottom: 0;
    }

    .mat-row {
      &.mat-no-data-row {
        .mat-cell {
          border: none;
        }
      }
    }
  }

  &.table-editable {
    background: unset !important;
    @apply bg-theme-line #{'!important'};
    .mat-cell {
      background-color: inherit;

      &.cell-editable {
        @apply bg-white #{'!important'};

        &:has(.mat-form-field.mat-form-field-invalid) {
          @apply relative border border-theme-red #{'!important'};
        }
      }

      &.mat-table-sticky {
        @apply z-10 #{'!important'};
      }
    }
  }

  &.table-default {
    .mat-header-row,
    .mat-row {
      @apply min-h-10 border-none items-stretch;

      .mat-header-cell,
      .mat-cell {
        @apply border border-transparent border-b-theme-gray-border;

        &:first-of-type {
          flex: 0 0 60px;
          @apply px-2 justify-center;
        }

        &:not(:first-of-type) {
          @apply px-2 border-l-theme-gray-border;
        }
      }

      &.row-selected,
      &.row-active {
        .mat-cell {
          @apply bg-primary-300;
        }
      }

      &.row-highlighted {
        .mat-cell {
          background-color: #d4f0f7;
        }
      }
    }

    .mat-header-row,
    .mat-header-cell {
      @apply bg-theme-line;
    }

    .mat-table-sticky-border-elem-left {
      @apply border-r-theme-gray-border #{!important};

      & + .mat-header-cell,
      & + .mat-cell {
        @apply border-l-transparent #{'!important'};
      }
    }

    .mat-no-data-row {
      @apply absolute left-0 w-full bg-theme-line;
    }

    &:has(> .mat-no-data-row) {
      @apply min-h-[140px];
    }
  }
}

// Dialog
.dialog-full-screen {
  height: 100svh;
  width: 100svw;
  max-width: 100svw !important;
  max-height: 100svh !important;

  .mat-dialog-container {
    border-radius: 0;
    padding: 0;
  }
}
.dialog-no-padding {
  .mat-dialog-container {
    padding: 0;
  }
}
// Tabs
.mat-tab-nav-bar {
  &.mat-tab-document-group {
    background-color: white !important;

    .mat-tab-list {
      border-bottom: none;
    }

    .mat-tab-link {
      opacity: 1;

      &.mat-tab-label-active {
        @apply text-primary;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 24px;
        height: 2px;
        background-color: #777777;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 24px;
        height: 2px;
        background-color: #777777;
      }

      &:first-of-type {
        &::before {
          display: none;
        }
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }
    }

    .mat-ink-bar {
      display: none;
    }
  }
}

// mat tab group
.mat-tab-group {
  .mat-tab-header {
    @apply bg-theme-line border-none #{'!important'};
  }

  .mat-tab-labels {
    border-bottom: 1px solid #e2e8f0;
  }

  .mat-tab-label {
    opacity: 1;

    &.mat-tab-label-active {
      @apply text-primary bg-white;
    }
  }

  .mat-ink-bar {
    bottom: unset;
    top: 0;

    &::after {
      content: '';
      position: absolute;
      bottom: -48px;
      border-top: 2px solid white;
      left: 0;
      right: 0;
      transition: none;
    }
  }
  &.tab-full-height {
    .mat-tab-body-wrapper {
      flex: 1 1 auto;
    }
  }
}

// Mat Drawer
.mat-drawer-container {
  .mat-drawer {
    &.detail-content {
      @apply w-full;
      @screen lg {
        width: calc(100% - 320px);
      }
    }
  }
}
