red-badge {
  &.default {
    @apply bg-white;
  }

  &.red {
    @apply bg-theme-red;
    color: white;
  }

  &.gray {
    @apply bg-gray-500;
  }

  &.small {
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
  }

  &.medium {
    padding: 4px 8px;
    border-radius: 16px;
    @apply text-b2 font-medium;
  }
}
