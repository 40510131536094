/* Perfect scrollbar */
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~pdfjs-dist/web/pdf_viewer.css';

.pdfjs-viewer-container {
    display: block;
    position: absolute;
    max-width: 100%;
    //width: 100%;

    .pdf-viewer {

        &#viewer {
            white-space: normal;
        }

        .page {
            position: relative;
            border: unset;
            border-bottom-width: unset;
            margin-bottom: 10px;
            box-shadow: 5px 5px 5px rgb(0 0 0 / 15%);
        }
    }

    &:last-child {
        border-bottom-width: 0;
    }
}

.pdf-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 20%;
    background-color: rgba(#fff, 0.5);
    z-index: 10;
}